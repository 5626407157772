
@import 'brand_variables.scss';

@if $brand == $brand-bt {
  .hide-on-bt {
    display: none !important;
  }
}

@if $brand == $brand-formula {
  .hide-on-fr {
    display: none !important;
  }
}
