.modal-dialog:not(.modal-lg, .modal-sm) {
  max-width: 480px;
}

.modal-content:not(.no-max-height) {
  max-height: calc(100vh - 100px);
  @include media-breakpoint-down(sm) {
    height: 100%;
    max-height: 100%;
  }
}

.modal-body {
  overflow-y: auto;
}

.modal-body, .modal-header {
  padding: 16px 24px;
}


@include media-breakpoint-down(sm) {
  .modal-dialog:not(.modal-lg, .modal-sm) {
    margin: 0;
    max-width: 100%;
    height: 100%;
  }
}

.modal-footer {
  padding: 12px;
  flex-shrink: 0;

  .btn {
    height: 48px;
    padding: 0;
  }
}

.modal-title {
  font-size: 18px;
  line-height: 28px;
}

.modal-header-title {
  font-size: 26px;
  line-height: 40px;
}

.modal-header {
  display: block;
  padding: 12px 24px;
}

body.darker-backdrop .modal-backdrop.show {
  opacity: .9;
}
