$body-bg: $gray-100;
$body-color: #333;
$tooltip-max-width: 216px !default;
$tooltip-bg: #333;
$navbar-background: red;
$list-group-bg: lighten($body-bg, 5%);
$pagination-bg: lighten($body-bg, 5%);
$pagination-hover-bg: darken($pagination-bg, 10%);
$pagination-disabled-bg: lighten($body-bg, 5%);
$input-focus-box-shadow: 0 0 0 0.2rem rgba($focus-blur, 0.25);
$input-focus-border-color: $focus-border;
