@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-select {
  border: 1px solid #ccc;
  margin-top: 4px;

 .ng-select-container {
    height: auto;
    background: $gray-100;
    border-radius: 0px;
    border: 0px;
    padding: 11px;

    .ng-value-container {
      padding-left: 0px;

      .ng-placeholder {
        color: #424850;
      }
    }
  }

  &.ng-select-single .ng-select-container {
    height: auto;

    .ng-value-container .ng-input {
      height: 100%;
      top: 0;
      display: flex;
    }
  }

  &.ng-select-focused:not(.ng-selected-opened) > .ng-select-container {
    border-color: $focus-border;
    box-shadow: 0 0 0 0.2rem transparentize($color: $focus-blur, $amount: .75);
    background: #fff;
  }

  .ng-arrow-wrapper .ng-arrow {
    pointer-events: none;
    display: inline-block;
    height: 16px !important;
    width: 20px !important;
    background-image: url(/assets/icons/ic-caret-down.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px;
  }

  &.ng-select-opened>.ng-select-container .ng-arrow {
    border: 0;
    background-image: url(/assets/icons/ic-caret-up.svg);
  }

  &.ng-select-disabled .ng-select-container {
    background: $gray-200;
  }
}

.ng-dropdown-panel {
  @extend .light-shadow;

  &.ng-select-bottom {
    border-radius: 0;
  }

  .ng-dropdown-panel-items .ng-option {
    &.ng-option-selected {
      &:not(:hover) {
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
      &:hover {
        color: $primary;
      }

      .ng-option-label {
        font-weight: normal;
      }
    }

    &.ng-option-marked {
      background-color: $gray-100;
    }

  }
}
