$best-seller-size: 80px;

.bookmark {
  display:      flex;
  font-size:    14px;
  line-height:  16px;
  position:     absolute;
  text-align:   center;
  z-index:      1;

  &::before {
    border:               $best-seller-size / 2 solid $primary;
    border-bottom-color:  transparent;
    border-right-color:   transparent;
    content:              "";
    left:                 0;
    position:             absolute;
    top:                  0;
    z-index:              -1;
  }
}