$btfit_00: #f5f5f5 !default;
$btfit_01: #ffffff !default;
$btfit_02: #339899 !default;
$btfit_03: #66cccc !default;
$btfit_04: #42b9c0 !default;
$btfit_05: #232323 !default;
$btfit_06: #707070 !default;

.oswald-bold-100 {
  color: $btfit_00;
  font-family: "Oswald", sans-serif;
  font-size: 50px;
  font-weight: 500;
}

.oswald-bold-60 {
  color: $btfit_05;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-weight: 500;
}

.oswald-bold-30 {
  color: $btfit_00;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.oswald-extralight-30 {
  color: $btfit_00;
  font-family: "Oswald", sans-serif;
  font-size: 23px;
  font-weight: lighter;
}

.oswald-extralight-40 {
  color: $btfit_05;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  font-weight: lighter;
}

.opensans-regular-30 {
  color: $btfit_06;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  a {
    color: #707070;
    text-decoration: underline;
  }
}

// Tablet
@media only screen and (min-width: 1023px) and (max-width: 1365px) {
  .oswald-bold-100 {
    font-size: 80px;
  }

  .oswald-bold-60 {
    font-size: 60px;
  }

  .oswald-bold-30 {
    font-size: 22px;
  }

  .oswald-extralight-30 {
    font-size: 25px;
  }

  .oswald-extralight-40 {
    font-size: 40px;
  }
  .opensans-regular-30 {
    font-size: 30px;
  }
}

// Desktop
@media only screen and (min-width: 1366px) {
  .oswald-bold-100 {
    font-size: 89px;
  }

  .oswald-bold-60 {
    font-size: 50px;
  }

  .oswald-bold-30 {
    font-size: 30px;
  }

  .oswald-extralight-30 {
    font-size: 30px;
  }

  .oswald-extralight-40 {
    font-size: 30px;
  }

  .opensans-regular-30 {
    font-size: 20px;
  }
}
