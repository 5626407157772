// m = modal
// h = height
// w = width
// mg = margin
$m-border-w: 1px;
$m-vertical-mg: 28px;
$m-title-h: 65px;
$m-footer-h: 73px;
$m-searchbox-h: 74px;
$title-two-lines-h: 89px;

// Common modal header, body and footer
$m-hbf: $m-title-h + $m-footer-h;

@mixin modal-max-height($offset) {
  max-height: calc(
    100vh - #{($m-vertical-mg * 2) + ($m-border-w * 2) + $offset}
  );
  @include media-breakpoint-down(sm) {
    max-height: calc(100vh - #{($m-border-w * 2) + $offset});
  }
}

.modal-body {
  app-modal-gym-benefit-list & {
    @include modal-max-height($m-hbf);
  }

  app-modal-agenda & {
    @include modal-max-height($title-two-lines-h + $m-footer-h);
  }

  app-modal-alert & {
    @include modal-max-height($m-hbf);
  }

  app-modal-confirm & {
    @include modal-max-height($m-hbf);
  }

  app-modal-gym-activity & {
    $tabs-height: 96px;
    @include modal-max-height($tabs-height + $m-searchbox-h + $m-footer-h);
  }

  app-modal-gym-age & {
    @include modal-max-height($m-hbf);
  }

  app-modal-gym-benefit & {
    @include modal-max-height($m-hbf);
  }

  app-modal-gym-plan & {
    @include modal-max-height($title-two-lines-h + $m-footer-h);
  }

  app-modal-gym-select & {
    @include modal-max-height($m-title-h + $m-searchbox-h + $m-footer-h);
  }
}

.modal-content:not(.no-max-height) {
  max-height: none;
  @include media-breakpoint-down(sm) {
    height: 100%;
    max-height: 100%;
  }
}

.adjust-on-ie--h50 {
  height: 50px;
}
