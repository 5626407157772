$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-input-group: 5 !default;
$zindex-agenda-header: 900 !default;
$zindex-footer: 990 !default;
$zindex-select-options: 991 !default;
$zindex-nav-carousel: 995 !default;
$zindex-nav-carousel-shadow: 1000 !default;
$zindex-faqbot: 1000 !default;
$zindex-alert: 1055 !default; // Between modal backgound and modal content
$zindex-loading: 1100 !default;
