$brand: 'bt';

// NAVBAR
$navbar-height: 80px !default;
$navbar-height-sm: 44px !default;
$brand-navbar-top-height: 120px !default;
$brand-navbar-top-height-sm: 84px !default;
$brand-navbar-top-background: $primary;
$brand-navbar-img-padding: 12px 10px;
$brand-navbar-width: 80px;
$brand-navbar-width-sm: 56px;
$brand-navbar-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.078);
