.form-validate {
  .form-control, .form-custom {
    transition: border-color .5s ease-out;

    &.ng-touched.ng-dirty {
      &.ng-valid {
        &, .border-validate {
          border-bottom-color: $success !important;
        }
      }
      &.ng-invalid {
        &, .border-validate {
          border-bottom-color: $danger !important;
        }
        .text-validate {
          color: $danger;
        }
      }
    }
  }
}

input.form-control:disabled {
  background: $gray-200;
}

.auto-height {
  height: auto;
}
