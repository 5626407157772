.dropdown-arrow.up-arrow {
  &::after {
    content: ' ';
    width: 24px;
    height: 24px;
    background-image: url('/assets/icons/ic-caret-up.svg');
    background-position-x: center;
    background-position-y: center;
    display: inline-block;
  }
  &.collapsed::after {
    background-image: url('/assets/icons/ic-caret-down.svg');
    background-position-x: center;
    background-position-y: center;
    display: inline-block;
  }

  &.light {
    &::after {
      background-image: url('/assets/icons/ic-caret-up-light.svg');
    }
    &.collapsed::after {
      background-image: url('/assets/icons/ic-caret-down-light.svg');
    }
  }
}
