/* You can add global styles to this file, and also import other style files */

@import './app/styles/functions/functions.scss';
@import './app/styles/variables/variables.scss';
@import './app/styles/mixins/mixins.scss';

$enable-rounded: false;
@import "~bootstrap/scss/bootstrap";
@import '~ngx-toastr/toastr-bs4-alert.scss';

@import '~swiper/css/swiper.min.css';

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500');
@import './app/styles/roboto';


body {
  -webkit-font-smoothing: antialiased;
  position: relative;
  overflow-y: scroll; /* This prevents content stagger when page loads */
  font-family: 'Roboto', sans-serif;
  color: $gray-700;

  @media print {
    background-color: $white;
  }
}

.main-wrapper {
  margin-top: $default-section-margin-top;

  &.sm {
    margin-top: 2rem;
  }

  @include media-breakpoint-down(sm) {
    margin-top: $default-section-margin-top-small + 5px;
  }
}

.light-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .04)
}

.overflow-y-auto {
  overflow-y: auto;
}

.centralize-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.close:focus {
  outline: 0;
}

.card {
  @extend .light-shadow;
  @media print {
    border: 0;
  }
}

@import './app/styles/bookmark.scss';
@import './app/styles/button.scss';
@import './app/styles/bookmark.scss';
@import './app/styles/card.scss';
@import './app/styles/dropdown.scss';
@import './app/styles/header.scss';
@import './app/styles/form.scss';
@import './app/styles/footer.scss';
@import './app/styles/modal.scss';
@import './app/styles/text.scss';
@import './app/styles/tooltip.scss';
@import './app/styles/brand.scss';

@import './app/styles/shared';

// @import './app/styles/libs/agm.scss';
@import './app/styles/libs/swiper.scss';
@import './app/styles/libs/select.scss';


@media all and (-ms-high-contrast:none) {
  @import './app/styles/styles-ie.scss';
}

// Custom styles for CookieConsent
@import '~cookieconsent/build/cookieconsent.min.css';

.cc-revoke, .cc-window {
  font-family: 'Roboto', sans-serif !important;
}

.cc-btn.cc-dismiss {
  font-family: 'Oswald', 'Arial Narrow', Arial, sans-serif;
  background-color: $primary !important;
  font-weight: 500;
  font-size: 1em;

  &:hover {
    background-color: darken($primary, 7.5%) !important;
    text-decoration: none;
  }
}

.cc-link:hover {
  color: $primary !important;
}

.mat-calendar-period-button {
  pointer-events: none !important;
}

table.mat-calendar-table {
  border-collapse: separate;
}

div.mat-calendar-body-cell-content {
  top: -2%;
  left: 0;
  width: 102%;
  height: 103%;
}

div.mat-calendar-body-selected {
  background-color: $primary;
}

.question-icon {
  cursor: pointer;
  color: $primary;
}

.divisor-line {
  border-top: 1px solid #e0e1e2;
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 10px;
}
