.tooltip {
  border: none;

  &.show {
    opacity: 1;
  }
  .arrow:before {
    top: -1px;
    bottom: -1px;
    border-top-color: white;
    border-bottom-color: white;
  }
}

.popover {
  border: none;
  text-align: center;
  will-change: opacity;
  opacity: 0;

  &.wait-to-show {
    opacity: 0;
  }

  &.force-show {
    opacity: 1;
  }

  &.popover-top .arrow:before {
    border-top-color: white;
    border-bottom-color: white;
  }
  &.popover-left .arrow:before {
    border-left-color: white;
    border-right-color: white;
  }
  &.popover-top .arrow {
    bottom: -7px;
  }
  &.popover-left .arrow {
    right: -7px;
  }
}

.popover .popover-content,
.tooltip .tooltip-inner {
  padding: 16px;
  background: white;
  color: $gray-700;
  border: 1px solid $gray-200;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.24);

  .gym-benefit-tooltip {
    .title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .badge {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 12px;
      padding: 6px 10px;
      border-radius: 32px;
    }
    .text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
